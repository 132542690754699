<template>
    <div>
        <ul class="flex py-3 border-b">
            <li class="mr-10"><router-link to="/" class="text-xl hover:text-orange-500">MapToGlobe</router-link></li>
            <li class="mr-6"><router-link to="/help" class="hover:text-orange-500">Help</router-link></li>
            <li class="mr-6"><router-link to="/contact" class="hover:text-orange-500">Contact</router-link></li>
            <li class="mr-6"><router-link to="/legal" class="hover:text-orange-500">Legal</router-link></li>
            <li class="mr-6"><a href="https://github.com/kurt1288/MapToGlobe" class="hover:text-orange-500 flex items-center">
                Github
                <svg class="h-4 w-4 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
            </a></li>
        </ul>
    </div>
</template>