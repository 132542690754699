
    import { computed, defineComponent, onBeforeMount, ref } from 'vue';
    import * as emailjs from 'emailjs-com';

    export default defineComponent({
        setup() {
            const win = window as any;

            const replyTo = ref("");
            const message = ref("");
            const recaptchaLoaded = ref(false);
            const sendError = ref("");
            const messageSending = ref(false);
            const messageSent = ref(false);

            emailjs.init("user_ozQWg3nonfN5NBwObrIuf");

            const submitDisabled = computed(() => {
                if ((replyTo.value.trim().length <= 0 || message.value.trim().length <= 0) || messageSending.value)
                    return true;
                else
                    return false;
            });

            const submit = async () => {
                try {
                    await emailjs.sendForm("mailgun_service_vq0tfvq", "template_iqu4hfn", document.getElementById("contactForm") as HTMLFormElement);
                }
                catch(ex) {
                    sendError.value = `Unable to send the message: ${ex}`;
                }
                messageSent.value = true;
            }

            const renderRecaptcha = () => {
                win.grecaptcha.render("g-recaptcha", {
                    sitekey: "6LdWpBgUAAAAAKsLoyZujUEOuvatTvYirAESqFAV",
                    size: "invisible",
                    callback: (response: any) => {
                        submit();
                    }
                });
            };

            onBeforeMount(() => {
                win.recaptchaApiLoaded = () => {
                    recaptchaLoaded.value = true;
                    renderRecaptcha();
                };

                const script = document.createElement('script');
                script.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaApiLoaded&render=explicit";
                script.async = true;
                script.defer = true;

                document.head.appendChild(script);
            });

            const validate = () => {
                messageSending.value = true;
                win.grecaptcha.execute();
            }

            return { submit, sendError, replyTo, message, submitDisabled, validate, recaptchaLoaded, messageSent };
        }
    });
